import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import Styled from "./styles";
import { ContentfulLatestPosts } from "../../types";
import Container from "../Container";

interface LatestPostsProps extends ContentfulLatestPosts {}

export const fragment = graphql`
  fragment LatestPosts on ContentfulLatestPosts {
    id
    internal {
      type
    }
    numberOfPosts
  }
`;

const LatestPosts: React.FC<LatestPostsProps> = () => {
  const data = useStaticQuery(graphql`
    {
      posts: allContentfulPost(
        filter: { slug: { ne: "dummy" } }
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            id
            title
            slug
            date(formatString: "D MMMM YYYY")
            tags {
              title
            }
            excerpt {
              excerpt
            }
            content {
              raw
            }
            metadata {
              ...Metadata
            }
          }
        }
      }
    }
  `);

  if (!data || !data.posts) return null;

  const { edges } = data.posts;

  return (
    <Container width="narrow">
      <Styled.LatestPosts>
        <h2>Latest Posts</h2>
        {edges.map(({ node: post }) => (
          <Styled.Post key={post.id}>
            <Link to={`/blog/${post.slug}`}>
              <h3>{post.title}</h3>
            </Link>
            <div>
              <div className="content">{post.excerpt.excerpt}</div>
              {post.content && (
                <Link className="read-more" to={`/blog/${post.slug}`}>
                  Read More
                </Link>
              )}
            </div>
            <div className="date">
              Posted: <span>{post.date}</span>
            </div>
            {post.tags && (
              <div className="tags">
                {post.tags.map((tag) => (
                  <div key={tag.title} className="tag">
                    {tag.title}
                  </div>
                ))}
              </div>
            )}
          </Styled.Post>
        ))}
      </Styled.LatestPosts>
    </Container>
  );
};

export default LatestPosts;
