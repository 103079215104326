import styled from "styled-components";

const LatestPosts = styled.section`
  position: relative;
  padding: 50px 0 100px 0;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;

  h2 {
    border-bottom: 3px solid #d1503f;
    display: inline-block;
  }
`;

const Post = styled.article`
  position: relative;
  border-bottom: 1px solid #edb9b2;
  padding-bottom: 40px;

  h3 {
    font-family: "Roboto Slab", serif;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .read-more {
    display: inline-block;
    border-bottom: 2px solid #d1503f;
    font-weight: bold;
  }

  .content {
    white-space: break-spaces;
    margin-bottom: 20px;
  }

  .tags {
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #999;
  }

  .tag {
    display: inline-block;
    background-color: #d1503f;
    border-radius: 10px;
    padding: 0px 10px;
    margin-left: 5px;
    margin-bottom: 5px;
    color: #fff;

    &:first-child {
      margin-left: 0;
    }
  }

  .date {
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    color: #999;
    font-weight: 700;

    span {
      color: #333;
    }
  }
`;

export default {
  LatestPosts,
  Post,
};
