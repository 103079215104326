import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import Container from "../Container";
import Styled from "./styles";
import { ContentfulLatestProjects } from "../../types";

interface LatestProjectsProps extends ContentfulLatestProjects {}

export const fragment = graphql`
  fragment LatestProjects on ContentfulLatestProjects {
    id
    internal {
      type
    }
    numberOfProjects
  }
`;

const LatestProjects: React.FC<LatestProjectsProps> = () => {
  const data = useStaticQuery(graphql`
    {
      projects: allContentfulProject(filter: { slug: { ne: "dummy" } }) {
        edges {
          node {
            id
            title
            slug
            link
            image {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            content {
              raw
            }
            metadata {
              ...Metadata
            }
          }
        }
      }
    }
  `);

  if (!data || !data.projects) return null;

  const { edges } = data.projects;

  return (
    <Container width="wide">
      <h2>Recent Projects</h2>
      <Styled.LatestProjects>
        {edges.map(({ node: project }) => (
          <Styled.Project key={project.id}>
            <Link to={`/projects/${project.slug}`}>
              <div>
                {project.image && <Image fluid={project.image.fluid} />}
                <h3>{project.title}</h3>
              </div>
            </Link>
          </Styled.Project>
        ))}
      </Styled.LatestProjects>
    </Container>
  );
};

export default LatestProjects;
