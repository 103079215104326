import React from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../layout";
import Metadata from "../components/Metadata";
import { ContentfulPage } from "../types";
import componentMap from "../util/contentfulComponentMap";

interface ContentfulPageProps extends PageProps {
  data: {
    page: ContentfulPage;
  };
  pathContext: any;
}

export const query = graphql`
  query PageQuery($contentful_id: String!) {
    page: contentfulPage(contentful_id: { eq: $contentful_id }) {
      title
      metadata {
        ...Metadata
      }
      content {
        ...LatestPosts
        ...LatestProjects
      }
    }
  }
`;

const PageTemplate: React.FC<ContentfulPageProps> = ({ data = {} }) => {
  const { page } = data;

  return (
    <Layout>
      <Metadata {...(page.metadata ? page.metadata : {})} />
      {page.content &&
        page.content.map((component) => {
          const Component = componentMap[component?.internal?.type];

          if (!Component) return null;

          return <Component key={component.id} {...component} />;
        })}
    </Layout>
  );
};

export default PageTemplate;
