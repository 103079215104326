import styled from "styled-components";

const LatestProjects = styled.section`
  position: relative;
  margin-bottom: 100px;
  display: flex;
`;

const Project = styled.div`
  border-radius: 10px;
  overflow: hidden;
  max-width: 50%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  h3 {
    font-weight: normal;
    text-align: center;
    padding: 0 20px;
    margin: 5px 0;
  }
`;

export default {
  LatestProjects,
  Project,
};
